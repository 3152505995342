.photo-modal {
    &__hidden {
        display: none !important;
    }

    &__buttons {
        margin-top: 30px;
        display: flex;
        justify-content: end;
        gap: 24px;

        button {
            height: 40px;
            min-width: 121px;
            font-size: 16px;
            line-height: 24px;
            padding: 8px 8pxx;
        }

        .button--secondary {
            font-weight: 700 !important;
        }
    }
}
