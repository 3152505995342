.page-profile {
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;

    .main-div {
        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1048px;
        height: 100%;

        .navigation-bar-div {
            margin-bottom: 32px;
        }

        .action-bar-div {
            margin-bottom: 32px;
            display: flex;
            gap: 32px;

            button {
                height: 40px;
                min-width: 124px;
                font-size: 16px;
                line-height: 24px;
                padding: 8px 40px;
            }

            .button--secondary {
                font-weight: 700 !important;
            }
        }

        .terms-div {
            background-color: white;
            padding: 40px 24px;
            height: 100%;

            .terms-of-use-frame {
                width: 100% ;
                height: calc(100%  - 24px - 24px);
                margin-top: 32px;
                border: 0.1px solid #9f9f9f;
                color: var(--color-font);
                font-family: var(--font-primary);
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }
}
