.page--login {
    .login-form {
        text-align: center;
        width: calc(468px - 20px - 20px);
        height: calc(430px - 40px - 40px);
        padding: 40px 20px;
        background-color: var(--color-white);
        border: 0px solid transparent;
        border-radius: 0px;

        h2 {
            text-align: center;
            margin-bottom: 48px;
        }

        .field-message {
            text-align: left;
            color: var(--color-gray);
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin: 4px 0px 16px 11px;
            padding: 0;

            &--error {
                color: var(--color-error);
                text-decoration: underline;
            }
        }

        &__links {
            margin-bottom: 49px;
            margin-top: -40px;
            text-align: right;

            &__link {
                color: var(--color-dark-gray);
                font-size: 16px;
                font-weight: 400;
                line-height: 23px;
                text-decoration: underline;
                transition: color var(--transition-speed);

                &:hover {
                    color: var(--color-highlight);
                }
            }
        }
    }

    .privacy-div{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .privacy-button{
            background-color: transparent;
            border: 0px solid transparent;
            border-radius: 0px;
            color: var(--color-white);
            cursor: pointer;
            font-family: var(--font-primary);
            font-size: 16px;
            font-weight: 400;
            line-height: 23px;
            text-decoration: underline;
            width: 50%;
            height: 48px;
            padding: 8px 24px;
            transition: background-color var(--transition-speed);
        
            &:hover {
                background-color: var(--color-highlight);
            }
        
        }
    }
}
