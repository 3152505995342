.page-warranty-detail {
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;

    .main-div {
        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1048px;

        .navigation-bar-div {
            margin-bottom: 32px;
        }

        .action-bar-div {
            margin-bottom: 32px;
            display: flex;
            justify-content: end;
            gap: 24px;

            button {
                height: 40px;
                min-width: 124px;
                font-size: 16px;
                line-height: 24px;
                padding: 8px 40px;
            }
        }

        .title-bar-div {
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;

            .header-container {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                justify-content: left;
                align-items: center;
                background-color: var(--color-night);
                padding: 12px 16px;
                min-height: calc(56px - 12px - 12px);
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                font-size: 16px;
                font-weight: 900;
                line-height: 24px;
                color: white;
                font-family: var(--font-primary);
            }

            .body-container {
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                justify-content: s;
                align-items: center;
                padding: 12px 16px;
                min-height: calc(56px - 12px - 12px);
                background-color: var(--color-off_white);
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: var(--color-dark-gray);
                font-family: var(--font-primary);

                .status-div {
                    display: flex;
                    flex-direction: row;
                    gap: 6px;
                    justify-content: left;
                    align-items: center;

                    .status-accepted {
                        background-color: var(--color-done);
                        width: 6px;
                        height: 32px;
                    }

                    .status-reopen {
                        background-color: var(--color-yellow);
                        width: 6px;
                        height: 32px;
                    }
                }
            }
        }

        .photos-container {
            display: grid;
            gap: 16px 16px;
            grid-template-columns: repeat(4, 1fr);
        }
    }
}
