.photo-editor {
    display: flex;
    flex-direction: row;
    height: calc(780px - 40px - 30px);
    // justify-content: center;

    .photo-editor-editor {
        height: 100%;
        width: 480px;
        min-width: 450px;
        position: relative;
        background-color: black;
        border-radius: 8px;
        display: flex;

        .photo {
            max-height: 100%;
            max-width: 100%;
            border-radius: 8px;

            position: absolute;
            object-fit: cover;
            object-position: center;
        }

        .date {
            position: absolute;
            left: 16px;
            bottom: 38px;
            z-index: 1;
            color: white;
            font-size: 24px;
            font-weight: 400;
            line-height: 28px;
            text-align: left;
        }

        .time {
            position: absolute;
            left: 16px;
            bottom: 16px;
            z-index: 1;
            color: white;
            font-size: 24px;
            font-weight: 400;
            line-height: 28px;
            text-align: left;
            background-color: black;
            padding: 2px;
        }

        .offline {
            position: absolute;
            left: 8px;
            top: 8px;
            padding: 4px 6px;
            background-color: black;
            color: white;
            border-radius: 4px;
            z-index: 1;
            font-size: 16px;
            font-weight: 700;
            line-height: 18px;
        }

        .covers {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            z-index: 1;
        }

        .editor {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            z-index: 15;
        }
    }

    .photo-editor-tools {
        width: 45px;
        height: 100%;
        margin-left: 30px;
        display: flex;
        flex-direction: column;
        gap: 50px;
        justify-content: center;

        .button-icon {
            background-color: transparent;
            border: 0px solid transparent;
            cursor: pointer;
            width: 45px;
            height: 45px;
            padding: 0;
            margin: 0;
            transition: background-color var(--transition-speed);

            &:hover {
                background-color: var(--color-highlight);
            }

            img {
                height: 45px;
                width: 45px;
            }

            &.hidden {
                display: none;
            }
        }
    }
}
