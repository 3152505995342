.layout--login {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: var(--inner-height);
    position: relative;
    margin: 0 !important;

    .background {
        background-image: url('../../../public/image/login_bg.png');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        left: 0;
        top: 0;
        pointer-events: none;
        position: absolute;
        width: 100%;
        z-index: -2;

        .layer {
            background-color: black;
            opacity: 70%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }
    }

    header {
        z-index: -2;
        left: 0;
        top: 0;
        position: absolute;
        width: calc(100% - 130px);
        height: 68px;
        padding: 0;
        background-color: black;
        display: flex;
        justify-content: right;
        align-items: center;
        padding-right: 130px;
    }

    .logo {
        left: 0;
        top: 0;
        position: absolute;
        height: 168px;
        width: 140px;
        z-index: 0;
        cursor: pointer;
    }

    .copyright {
        left: 0;
        bottom: 0;
        width: 100%;
        text-align: center;
        position: absolute;
        color: var(--color-white);
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        margin: 0 0 48px 0;
        z-index: -1;
    }

    .language-drop-down {
        position: absolute;
        display: inline-block;
        right: 130px;
        top: 16px;
    }

    .language-drop-down-content {
        display: none;
        position: absolute;
        background-color: #f1f1f1;
        min-width: 142px;
        // box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    }

    .language-drop-down-content a {
        text-decoration: none;
        display: block;
        min-width: calc(142px - 24px - 24px);
        height: calc(36px - 6px - 6px);
        padding: 6px 24px;
        text-align: center;
        background-color: var(--color-off-white);
        color: var(--color-dark);
        cursor: pointer;
        font-family: var(--font-primary);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        transition: background-color var(--transition-speed);
    }

    .language-drop-down-content a:hover {
        background-color: var(--color-highlight);
    }

    .language-drop-down:hover .language-drop-down-content {
        display: block;
    }

    .language-drop-down:hover .button--secondary {
        background-color: var(--color-secondary);
    }
}
