.table {
    &__header {
        background-color: var(--color-night);
        color: white;
        display: flex;
        padding: 12px 16px;
        min-height: calc(48px - 12px - 12px);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;

        &__column {
            align-items: center;
            display: flex;
            font-size: 16px;
            font-weight: 900;
            line-height: 24px;
            position: relative;
            justify-content: flex-start;
            gap: 10px;

            &--align-left {
                text-align: left;
                justify-content: flex-start;
            }

            &--align-center {
                text-align: center;
                justify-content: center;
            }

            &--align-right {
                text-align: right;
                justify-content: flex-end;
            }

            .order {
                // left: calc(100% - 24px - 12px);
                pointer-events: none;
                //  position: absolute;

                height: 28px;
                width: 32px;
                cursor: pointer;
            }
        }
    }

    &__body {
        background-color: #ffffff;
        display: flex;
        flex-direction: column;

        &__row {
            box-shadow: inset 0 -1px 0 0 #f5f7fa;
            display: flex;
            padding: 10px 24px;
            min-height: calc(44px - 10px - 10px);
            align-items: center;
        }

        &__row:nth-child(odd) {
            background-color: #f0f0f0;
        }

        &__column {
            align-items: center;
            display: flex;
            font-size: 16px;
            font-weight: 400;
            //  line-height: 24px;

            &--align-left {
                text-align: left;
                justify-content: flex-start;
            }

            &--align-center {
                text-align: center;
                justify-content: center;
            }

            &--align-right {
                text-align: right;
                justify-content: flex-end;
            }
        }

        &__empty {
            font-size: 14px;
            text-align: center;
            padding: 14px;
        }
    }

    &__pagination {
        align-items: center;
        background-color: #9f9f9f;
        color: white;
        display: flex;
        font-size: 14px;
        font-weight: 400;
        justify-content: flex-end;
        line-height: 24px;
        padding: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        &__pager {
            align-items: center;
            background-color: #dee2e6;
            display: flex;
            margin-right: 12px;
            margin-left: 16px;
            border-radius: 4px;
            gap: 1px;
            padding: 1px;
            overflow-y: auto;

            button {
                align-items: center;
                background-color: white;
                border: 0;
                //border-radius: 4px;
                display: flex;
                height: 34px;
                justify-content: center;
                min-width: 26px;
                width: 30px;
                font-size: 14px;
                font-weight: 400;
                color: #626262;
                line-height: 24px;
                cursor: pointer;
                transition: background-color var(--transition-speed);

                &:hover {
                    background-color: var(--color-highlight);
                }

                &[disabled] {
                    pointer-events: none;

                    img {
                        opacity: 0.5;
                    }
                }

                &.selected {
                    background-color: #626262;
                    color: white;
                }

                img {
                    height: 12px;
                }
            }
        }

        button {
            align-items: center;
            background-color: white;
            border: 0;
            //  border-radius: 4px;
            display: flex;
            height: 34px;
            justify-content: center;
            min-width: 26px;
            width: 30px;

            &[disabled] {
                pointer-events: none;

                img {
                    opacity: 0.5;
                }
            }

            img {
                height: 12px;
            }
        }
    }

    &--simple-header {
        .table__header {
            background-color: var(--color-white);
            box-shadow: inset 0 -1px 0 0 #f5f7fa;
        }
    }
}
