.menu {
    .menu__menu-group {
        padding: 0 0 0 0px;
        margin: 0;

        @media (max-width: 576px) {
            display: flex;
            flex-direction: column;
        }

        &:last-child {
            border-bottom: none;
        }

        @media (max-width: 1640px) {
            padding: 0 0 0 0;
        }
    }

    > .menu__menu-group {
        padding-bottom: 11px;
        padding-left: 0;
        padding-top: 20px;

        > .menu__menu-element {
            margin: 0 0 0 0;
            width: calc(100% + 0px + 0px);
        }
    }
}
