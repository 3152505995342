.sidebar {
    background-color: var(--color-white);
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.08);
    height: calc(var(--inner-height) - 0px - 0px);
    padding: 0px 0;
    position: fixed;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 296px;

    .logo {
        width: 140px;
        height: 200px;
    }

    .scroll-bar {
        height: calc(100% - 0px - 0px);
        position: absolute;
        right: 20px;
        top: 0px;
        width: 17px;

        @media (max-width: 1640px) {
            display: none;
        }
    }

    .scroll {
        --scroll-height: 50px;
        --scroll-top: 0px;

        background-color: var(--color-light-gray);
        border-radius: 8.5px;
        height: var(--scroll-height);
        position: absolute;
        top: var(--scroll-top);
        width: 17px;
    }

    .profile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;
        padding: 0 40px;
        width: calc(100% - 40px - 40px);
        margin-bottom: 40px;
        cursor: pointer;

        &__name-layout {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;
            gap: 10px;
            cursor: pointer;

            width: 100%;

            .icon {
                height: 22px;
                width: 22px;
                cursor: pointer;
            }

            .name {
                margin-left: 0px;
                font-size: 20px;
                line-height: 30px;
                cursor: pointer;
            }
        }

        .email {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            cursor: pointer;
        }

        &:hover {
            background-color: var(--color-primary);

            .name {
                color: white;
            }

            .email {
                color: white;
            }
        }
    }

    .logout-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 12px;
        padding: 0;
        height: 40px;
        width: 218px;
        margin-bottom: 48px;

        &__icon {
            height: 24px;
            width: 24px;
        }
    }
}
