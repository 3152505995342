.add-dealership-modal {
    height: auto;

    .field--text {
        //margin-bottom: 30px !important;
    }

    .field--email {
       // margin-bottom: 30px !important;
    }

    .dropdown-container {
        //margin-bottom: 30px !important;
    }

    .check-box-group {
        height: auto;
        display: flex;
        flex-direction: column;
        margin-top: 0;
        padding-top: 0;

        .margin-top {
            margin-top: 30px;
        }

        .check-box-container {
            color: var(--color-font);
            font-family: var(--font-primary);
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            padding: 8px 0px;
            display: flex;
            flex-direction: row;
            justify-content: left;
            gap: 16px;

            label {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 0;
                margin: 0;
            }
        }
    }

    .divider {
        height: 1px;
        width: 100%;
        background-color: black;
        opacity: 0.2;
    }

    &__buttons {
        margin-top: 16px !important;
        display: flex;
        justify-content: end;
        gap: 24px;

        button {
            height: 40px;
            min-width: 121px;
            font-size: 16px;
            line-height: 24px;
            padding: 8px 8px;
        }

        .button--secondary {
            font-weight: 700 !important;
        }
    }

    .error-message {
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: var(--color-error);
        font-family: var(--font-primary);
        margin-top: 8px;
        margin-bottom: 30px;
        margin-left: 16px;
    }
}
