.modal-container {
    align-items: center;
    background-color: rgba(2, 103, 170, 0.3);
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10000;

    @media (max-width: 576px) {
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }

    .modal {
        min-width: 514px;
        background-color: var(--color-white);
        border-radius: 8px;
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.08);
        max-height: calc(100vh - 25px - 25px );
        overflow-y: scroll;
        padding: 0;
        margin: 0;

        @media (max-width: 576px) {
            max-height: 100%;
            height: 100%;
            width: 100%;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        @media (max-width: 576px) {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            width: 100%;
        }

        &__header {
            height: calc(66px - 16px - 16px);
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: black;
            border-radius: 8px;
            padding: 16px 24px;

            h3 {
                color: white;
            }

            .close-button {
                background-color: transparent;
                border: 0px solid transparent;
                border-radius: 1px;
                cursor: pointer;
                width: 26px;
                height: 26px;
                padding: 0;
                margin: 0;
                margin-left: 24px;
                transition: background-color var(--transition-speed);

                &:hover {
                    background-color: var(--color-highlight);
                }
            }
        }

        &__body {
            padding: 30px 24px;
            display: flex;
            flex-direction: column;
        }

        &__footer {
            padding: 16px 24px;
            display: flex;
            justify-content: right;
            align-items: right;

            button {
                height: 40px;
                min-width: 155px;
                font-size: 16px;
                line-height: 24px;
                padding: 8px 40px;
            }

            .button-left-margin {
                margin-left: 15px !important;
            }
        }
    }
}
