.page--terms {
    .terms-form {
        text-align: center;
        width: calc(633px - 24px - 24px);
        height: calc(622px - 40px - 40px);
        padding: 40px 24px;
        background-color: var(--color-white);
        border: 0px solid transparent;
        border-radius: 0px;

        h2 {
            text-align: center;
            margin-bottom: 32px;
        }

        .terms-container {
            border: 0.1px solid #9f9f9f;
            border-bottom: none;
            border-radius: 0px;
            color: var(--color-font);
            font-family: var(--font-primary);
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            padding: 24px;
            text-align: left;
        }
        .terms-of-use-frame {
            width: calc(100% );
            height: 340px;
            border: 0.1px solid #9f9f9f;
            border-bottom: none;
            border-radius: 0px;
            color: var(--color-font);
            font-family: var(--font-primary);
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
        }

        .agree-container {
            border: 0.1px solid #9f9f9f;
            border-radius: 0px;
            color: var(--color-font);
            font-family: var(--font-primary);
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            padding: 12px 24px;
            margin-bottom: 32px;
            display: flex;
            flex-direction: row;
            justify-content: left;
            gap: 24px;

            label {
                display: flex;
                align-items: center;
            }
        }

        footer {
            display: flex;
            justify-content: right;
        }
    }
}
