.dropdown-container {
    width: 100%;

    .dropdown {
        height: calc(48px - 2px);
        width: 100%;
        border: 0px solid transparent;
        background-color: var(--color-dark-gray);
        color: white;
        border-radius: 0px;
        text-align: center;
        padding: 0;
        margin: 0;

        .disabled {
            filter: grayscale(0.5);
            opacity: 0.5;
            pointer-events: none;
        }

        .dropdown-container {
            height: 100%;
            width: 100%;
            padding: 0;
            margin: 0;
            position: relative;

            .dropdown-selected-item {
                display: inline-block;
                height: calc(48px - 12px - 12px);
                width: calc(100% - 24px - 24px);
                padding: 12px 24px 12px 24px;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                color: white;
                font-family: var(--font-primary);
                margin-bottom: 0px;
                transition: all 0.3s ease-in-out;
                cursor: pointer;
                z-index: 2;
                position: relative;

                :hover {
                    border: 0px solid transparent;
                    border-radius: 0px;
                    box-shadow: 0 0 4px var(--color-border);
                }

                &.placeholder {
                    font-weight: 400;
                    color: var(--color-placeholder);
                }

                @media (max-width: 400px) {
                    max-width: calc(100% - 50px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .dropdown-arrow {
                width: 12px;
                height: calc(48px - 24px - 2px);
                margin: 12px;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 2;

                cursor: pointer;
                -moz-transition: all 0.2s linear;
                -webkit-transition: all 0.2s linear;
                transition: all 0.2s linear;
                -moz-transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
            }

            .rotate {
                -moz-transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
            }

            .dropdown-items {
                border: 0px solid transparent;
                position: absolute;
                left: 0px;
                top: 0;
                height: fit-content;

                width: calc(100% - 8px - 8px);
                padding: 8px;
                margin-top: calc(48px);
                border: 0px solid transparent;
                border-radius: 0px;
                display: flex;
                flex-direction: column;
                z-index: 100;
                background: white;

                :hover {
                    border: 0px solid transparent;
                    border-radius: 0px;
                    background-color: var(--color-highlight);
                }

                .dropdown-items-item {
                    display: inline-block;
                    height: 100%;
                    width: 100;
                    height: calc(40px - 8px - 8px);
                    width: calc(100% - 16px - 16px);
                    padding: 8px 16px 8px 16px;
                    position: relative;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    color: var(--color-font);
                    font-family: var(--font-primary);
                    text-align: center;
                    cursor: pointer;

                    &.selected {
                        background: var(--color-off-white);
                        border-radius: 0px;
                    }

                    @media (max-width: 400px) {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
        }
    }

    .error-field {
        &.hidden {
            display: none;
        }

        .error-bubble {
            margin-left: 16px;
            width: 24px;
            height: 16px;
            margin-bottom: -3px;
        }

        .error-message {
            background-color: #fff0e6;
            border: 1px solid #fff0e6;
            border-radius: 16px;
            color: var(--color-font);
            font-family: var(--font-primary);
            outline: none;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            width: calc(100% - 24px - 24px);
            padding: 16px 24px;
            margin: 0;
        }
    }
}
