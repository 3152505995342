.draw-freehand {
    height: calc(100% - 4px);
    width: calc(100% - 4px);
    border: 2px solid yellow;
    border-radius: 8px;

    .canvas {
        height: 100%;
        width: 100%;
    }
}
