.menu {
    height: 100%;
    overflow-y: scroll;
    padding: 0 0px 0 0px;
    scrollbar-width: 0;
    width: calc(296px - 0px - 0px);

    &::-webkit-scrollbar {
        display: none;
    }
}
