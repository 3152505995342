.layout--default {
    background-color: #f4f7ff;
    display: flex;

    .page {
        margin-left: 296px;
        min-height: calc(var(--inner-height) - 48px - 48px);
        width: calc(100% - 48px - 48px);
        padding: 48px;
    }
}
