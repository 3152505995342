.warranty-card {
    border: 0px solid transparent;
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 250px;
    height: 380px;

    .photo-container {
        height: calc(100% - 40px - 8px);
        width: 100%;
        position: relative;
        cursor: pointer;
        border-radius: 8px;
        border: 0px solid transparent;
        transition: background-color var(--transition-speed);

        &:hover {
            border: 1px solid var(--color-highlight);
            width: calc(100% - 1px - 1px);
            height: calc(100% - 40px - 8px - 1px - 1px);
        }

        .photo {
            position: absolute;
            object-fit: cover;
            object-position: center;
            height: 100%;
            width: 100%;
            border-radius: 8px;
        }

        .status {
            position: absolute;
            right: 8px;
            bottom: 8px;
            padding: 2px;
            background-color: white;
            height: 13px;
            width: 13px;
            border-radius: 2px;
            z-index: 1;
        }

        .offline {
            position: absolute;
            left: 3px;
            top: 4px;
            padding: 6px 8px;
            background-color: black;
            color: white;
            border-radius: 4px;
            z-index: 1;
            font-size: 16px;
            font-weight: 700;
            line-height: 18px;
        }
    }

    &__buttons {
        margin-top: 8px;
        display: flex;
        gap: 8px;

        button {
            height: 40px;
            min-width: 121px;
            font-size: 16px;
            line-height: 24px;

            padding: 8px 8pxx;
        }
    }

    #snackbar {
        visibility: hidden;
        min-width: 250px;
        margin-left: -125px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 2px;
        padding: 16px;
        position: fixed;
        z-index: 1;
        left: 50%;
        bottom: 30px;
    }

    /* Show the snackbar when clicking on a button (class added with JavaScript) */
    #snackbar.show {
        visibility: visible; /* Show the snackbar */
        /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
        -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
        animation: fadein 0.5s, fadeout 0.5s 2.5s;
    }

    /* Animations to fade the snackbar in and out */
    @-webkit-keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }
        to {
            bottom: 30px;
            opacity: 1;
        }
    }

    @keyframes fadein {
        from {
            bottom: 0;
            opacity: 0;
        }
        to {
            bottom: 30px;
            opacity: 1;
        }
    }

    @-webkit-keyframes fadeout {
        from {
            bottom: 30px;
            opacity: 1;
        }
        to {
            bottom: 0;
            opacity: 0;
        }
    }

    @keyframes fadeout {
        from {
            bottom: 30px;
            opacity: 1;
        }
        to {
            bottom: 0;
            opacity: 0;
        }
    }
}
