.page-user-management {
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;

    .main-div {
        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1048px;

        .navigation-bar-div {
            margin-bottom: 32px;
            display: flex;
            flex-direction: row;
            align-items: end;
            flex-grow: 1;

            .navigation-bar-title-div {
                flex-grow: 1;
            }

            .navigation-bar-button-div {
                display: flex;
                gap: 24px;

                button {
                    height: 40px;
                    min-width: 136px;
                    font-size: 16px;
                    line-height: 24px;
                    padding: 8px 40px;
                }
            }
        }

        .search-bar-div {
            margin-bottom: 32px;
            display: flex;
            gap: 24px;

            button {
                height: 40px;
                min-width: 124px;
                font-size: 16px;
                line-height: 24px;

                padding: 8px 40px;
            }
        }
    }
}
