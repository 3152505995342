.message-modal {
    min-width: 514px;
    height: auto;

    &__message {
        color: var(--color-text);
        font-size: 18px;
        font-weight: 400;
        line-height: 24px;
        width: 100%;
        text-align: left;
    }
}
