.field--search {
    width: 100%;

    input {
        background-color: white !important;
        border: 1px solid #ced4da !important;
        height: 40px !important;
        padding: 8px 12px !important;
        width: 100% !important;
    }
}
