.photo-info-modal {
    &__row {
        display: flex;
        flex-direction: row;
        justify-content: left;
    }

    &__title {
        color: var(--color-text);
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        width: 170px !important;
    }

    &__description {
        color: var(--color-text);
        font-size: 16px;
        font-weight: 900;
        line-height: 30px;
        text-align: left;
        width: 140px;
    }
}
