.warranties-table {
    .button {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        min-width: 100px;
        height: 36px;
        background-color: var(--color-secondary);
    }
}
