.add-dealership-modal {
    height: auto;
    min-height: 240px;

    .row {
        display: flex;
        gap: 8px;
        width: 100%;

        .field--text {
            width: 50%;
        }
    }

    .divider {
        height: 1px;
        width: 100%;
        background-color: black;
        opacity: 0.2;
    }

    &__buttons {
        margin-top: 16px !important;
        display: flex;
        justify-content: end;
        gap: 24px;

        button {
            height: 40px;
            min-width: 121px;
            font-size: 16px;
            line-height: 24px;
            padding: 8px 8px;
        }

        .button--secondary {
            font-weight: 700 !important;
        }
    }

    .error-message {
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        color: var(--color-error);
        font-family: var(--font-primary);
        margin-top: 8px;
        margin-bottom: 30px;
        margin-left: 16px;
    }
}
