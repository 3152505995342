.modal {
    //min-width: 656px ;
   
    .edit-photo-modal {

        &__buttons {
            margin-top: 30px;
            display: flex;
            justify-content: end;
            gap: 24px;

            button {
                height: 40px;
                min-width: 121px;
                font-size: 16px;
                line-height: 24px;
                padding: 8px 8pxx;
            }
        }
    }
}