.warranty-groups-table-segment-filter {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    z-index: 1;

    .segment-drop-down {
    }

    .filter-image {
        background-image: url('../../../../public/image/ic_filter.svg');
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 16px;
        width: 36px;
        // cursor: pointer;
    }

    .segment-drop-down-content {
        display: none;
        position: absolute;
        left: 0;
        top: 20px;
        padding-top: 16px;
        width: 100%;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

        .selected {
            background-color: var(--color-off-white);
        }
    }

    .segment-drop-down-content a {
        text-decoration: none;
        display: block;
        min-width: calc(142px - 16px - 16px);
        height: calc(50px - 13px - 13px);
        padding: 13px 16px;
        text-align: left;
        background-color: white;
        color: var(--color-dark);
        cursor: pointer;
        font-family: var(--font-primary);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        transition: background-color var(--transition-speed);
    }

    .segment-drop-down-content a .selected {
        background-color: black;
    }

    .segment-drop-down-content a:hover {
        background-color: var(--color-highlight);
    }

    .segment-drop-down:hover .segment-drop-down-content {
        display: block;
    }

    .segment-drop-down:hover .filter-image {
        background-image: url('../../../../public/image/ic_filter_filled.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 16px;
        width: 36px;
    }
}
