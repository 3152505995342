input[type='checkbox'] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.checkbox {
    display: inline-block;
    height: 32px;
    width: 32px;
    min-width: 32px;
    margin: 0px 0px;
    background-color: transparent;
    border: 3px solid var(--color-primary);
    border-radius: 8px;
    cursor: pointer;

    .checked {
        height: 32px;
        width: 32px;
        font-size: 32px;
        line-height: 32px;
        color: white;
        background-color: var(--color-primary);
        margin-top: 10px;
    }

    &:hover {
        background-color: var(--color-highlight);
        border: 3px solid var(--color-primary);
    }
}

.checkbox--hidden {
    display: none;
}

.checkbox--active {
    display: block;
}
