@font-face {
    font-family: 'Suzuki Pro';
    font-style: normal;
    font-weight: 900;
    src: url('../font/suzuki_pro_bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Suzuki Pro';
    font-style: normal;
    font-weight: 700;
    src: url('../font/suzuki_pro_headline.ttf') format('truetype');
}

@font-face {
    font-family: 'Suzuki Pro';
    font-style: normal;
    font-weight: 400;
    src: url('../font/suzuki_pro_regular.ttf') format('truetype');
}
