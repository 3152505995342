.menu {
    &__menu-element {
        list-style: none;
        font-size: 16px;
        font-weight: 900;
        line-height: 28px;
        position: relative;
        display: inline-block;

        a {
            border: 0px solid transparent;
            border-radius: 0px;
            color: var(--color-menu);
            display: flex;
            gap: 16px;
            padding: 12px 40px;
            text-decoration: none;
            width: calc(100% - 40px - 40px);
            cursor: pointer;
            transition: background-color var(--transition-speed);

            img {
                width: 24px;
                height: 24px;
                // filter: invert(0) !important;
            }

            &:hover {
                background-color: var(--color-primary);
                color: white;

                img {
                    filter: invert(0) !important;
                }
            }

            &.active {
                background-color: black;
                color: var(--color-white);

                img {
                    filter: invert(1);
                }
            }

            &.highlighted {
                border-color: var(--color-primary);
            }
        }

        > .menu__menu-group {
            > .menu__menu-element {
                width: 100%;

                a {
                    padding: 12px 80px;

                    img {
                        display: none;
                    }
                }
            }
        }
    }
}
