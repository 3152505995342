.page-warranty-photo {
    align-items: center;
    width: 100%;
    display: flex;
    flex-direction: column;

    .main-div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
        width: 1048px;
    

        .navigation-bar-div {
            margin-bottom: 32px;
        }

        .photo-editor {
            height: 800px;

            .photo-editor-editor {
                background-color: transparent;
            }
        }

        .photo{
            width: fit-content;
            max-height: 900px;
            object-fit: contain;
            object-position: center;
            border-radius: 8px;
            margin-bottom: 20px;

        }
    }
}
