.page--terms-of-use {
    .terms-of-use-div {
        text-align: center;
        width:  calc(800px - 20px - 20px);
        height: calc(800px - 40px - 40px);
        padding: 40px 20px;
        background-color: var(--color-white);
        border: 0px solid transparent;
        border-radius: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .title-container {
            display: flex;
            flex-direction: row;
            margin-bottom: 32px;
            width: 100%;

            .button-back {
                background-color: transparent;
                border: 0px solid transparent;
                border-radius: 0px;
                color: var(--color-white);
                cursor: pointer;
                font-family: var(--font-primary);
                width: 38px;
                height: 38px;
                transition: background-color var(--transition-speed);
                display: flex;
                flex-direction: column;
                justify-content: center;

                &:hover {
                    background-color: var(--color-highlight);
                }
            }

            h2 {
                width: 100%;
                text-align: center;
                margin-right: 38px;
            }
        }

        .terms-of-use-frame {
            width: 100%;
            height: 100%;
            border: 0.1px solid #9f9f9f;
            color: var(--color-font);
            font-family: var(--font-primary);
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
        }

    }
}
