.page--forgot-password {
    .forgot-password-form {
        text-align: center;
        width: calc(468px - 20px - 20px);
        height: calc(390px - 40px - 40px);
        padding: 40px 20px;
        background-color: var(--color-white);
        border: 0px solid transparent;
        border-radius: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;

        .title-container {
            display: flex;
            flex-direction: row;
            margin-bottom: 32px;

            .button-back {
                background-color: transparent;
                border: 0px solid transparent;
                border-radius: 0px;
                color: var(--color-white);
                cursor: pointer;
                font-family: var(--font-primary);
                width: 38px;
                height: 38px;
                transition: background-color var(--transition-speed);
                display: flex;
                flex-direction: column;
                justify-content: center;

                &:hover {
                    background-color: var(--color-highlight);
                }
            }

            h2 {
                width: 100%;
                text-align: center;
                margin-right: 38px;
            }
        }

        .description {
            text-align: left;
            font-size: 16px;
            font-weight: 900;
            line-height: 30px;
            color: var(--color-font);
            font-family: var(--font-primary);
        }

        .field {
            margin-top: 24px;
        }

        .field-message {
            text-align: left;
            color: var(--color-gray);
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin: 4px 0px 32px 11px;
            padding: 0;

            &--error {
                color: var(--color-error);
                text-decoration: underline;
            }
        }
    }
}
