@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0');

// @font-face declarations moved to -> src/asset/scss/font.scss
@import './font.scss';

:root {
    --font-suzuki: 'Suzuki Pro', sans-serif;
    --font-primary: var(--font-suzuki);
    --color-blue: #00368f;
    --color-dark: #363638;
    --color-off_white: #f0f0f0;
    --color-gray: #b6b6b6;
    --color-dark-gray: #393939;
    --color-night: #161616;
    --color-menu: #9f9f9f;
    --color-green: #16ec2b;
    --color-light-gray: #f0f0f0;
    --color-error: #ec2152;
    --color-yellow: #ffd201;
    --color-white: #ffffff;
    --color-ghost: #d9d9d9;
    --color-off-white: #f0f0f0;
    --color-primary: var(--color-blue);
    --color-secondary: #de0039;
    --color-font: var(--color-dark);
    --color-placeholder: var(--color-gray);
    --color-highlight: var(--color-yellow);
    --color-border: #d6d6d6;
    --color-done: var(--color-green);
    --color-not-done: var(--color-pink);
    --transition-speed: 333ms;
}

html {
    font-size: 16px;
    scroll-behavior: smooth;
}

body {
    color: var(--color-font);
    font-family: var(--font-primary);
    font-size: 1rem;
    letter-spacing: normal;
    line-height: 1;
    margin: 0;
    overflow-y: auto;
}

h1 {
    text-align: left;
    font-size: 46px;
    font-weight: 700;
    line-height: 48px;
    margin: 0;
    color: var(--color-dark-gray);
    font-family: var(--font-primary);
}

h2 {
    text-align: left;
    font-size: 38px;
    font-weight: 700;
    line-height: 38px;
    margin: 0;
    color: var(--color-dark-gray);
    font-family: var(--font-primary);
}

h3 {
    text-align: left;
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
    margin: 0;
    color: var(--color-dark-gray);
    font-family: var(--font-primary);
}

label {
    text-align: center;
    font-size: 16px;
    font-weight: 900;
    line-height: 30px;
    margin: 0;
    color: var(--color-dark-gray);
    font-family: var(--font-primary);
}

p {
    text-align: left;
    font-size: 20px;
    font-weight: 900;
    line-height: 30px;
    margin: 0;
    color: var(--color-dark-gray);
    font-family: var(--font-primary);
}

.button {
    background-color: var(--color-primary);
    border: 0px solid transparent;
    border-radius: 0px;
    color: var(--color-white);
    cursor: pointer;
    font-family: var(--font-primary);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    min-width: 170px;
    height: 48px;
    padding: 8px 12px;
    transition: background-color var(--transition-speed);

    &:hover {
        background-color: var(--color-highlight);
    }

    &.hidden {
        display: none;
    }

    &[disabled] {
        background-color: var(--color-ghost);
        pointer-events: none;
        cursor: not-allowed;
    }

    &--ghost {
        background-color: white;
        border: 1px solid var(--color-primary);
        color: var(--color-primary);
        transition: border-color var(--transition-speed), color var(--transition-speed);

        &:hover {
            background-color: var(--color-white);
            border-color: var(--color-highlight);
            color: var(--color-highlight);
        }

        &[disabled] {
            background-color: var(--color-white);
            border-color: var(--color-gray);
            color: var(--color-gray);
            opacity: 0.25;
        }
    }

    &--secondary {
        background-color: var(--color-secondary);
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        min-width: 142px;
        height: 36px;
        padding: 6px 24px;
    }
}

.field {
    display: flex;
    flex-direction: column;
    margin: 0;

    input {
        background-color: var(--color-light-gray);
        border: 2px solid transparent;
        border-radius: 0px;
        color: var(--color-font);
        font-family: var(--font-primary);
        font-size: 16px;
        font-weight: 400;
        height: calc(48px - 12px - 12px - 1px - 1px);
        line-height: 24px;
        outline: none;
        padding: 12px 16px;
        transition: border-color var(--transition-speed);
        width: calc(100% - 16px - 16px - 1px - 1px);
        margin: 0;

        &:focus {
            border-color: var(--color-primary);
        }

        &::placeholder {
            color: var(--color-placeholder);
        }

        &[disabled] {
            opacity: 0.5;
        }
    }

    &--error {
        input {
            background-color: rgba(195, 8, 112, 0.1);
            border-color: var(--color-warning) !important;
        }
    }
}

.title-text-h2 {
    text-align: left;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
    color: var(--color-primary);
    font-family: var(--font-primary);
}

.title-text-h3 {
    text-align: left;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;
    margin: 0;
    color: var(--color-primary);
    font-family: var(--font-primary);
}

.header-text {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 17px;
    color: var(--color-dark);
    font-family: var(--font-primary);
}

.navigation-title {
    align-items: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    position: relative;

    @media (max-width: 576px) {
        flex-direction: column;
    }

    .title-text-h1 {
        margin-right: 6px;
    }

    a {
        text-decoration: none;
    }
}

.check-box-group {
    height: calc(48px - 20px);
    display: flex;
    flex-direction: row;
    justify-content: left;
    padding-top: 20px;

    label {
        margin-right: 26px;
    }

    input {
        appearance: none;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        border: 1px solid var(--color-primary);
        transition: 0.2s all linear;
        margin-right: 8px;
        position: relative;
        top: 4px;
    }

    input:checked {
        background: var(--color-primary);
        border: 1px solid var(--color-primary);
    }
}

.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: left;
    position: relative;
    margin-top: 30px;

    .column {
        flex-direction: column;
        margin-top: 0px;
    }
}

@keyframes loading {
    0% {
        border-color: rgb(40, 168, 223) transparent transparent transparent;
        transform: rotate(0deg);
    }

    12.5% {
        border-color: rgb(40, 168, 223) transparent transparent transparent;
    }

    25% {
        border-color: rgb(1, 103, 170) transparent transparent transparent;
        transform: rotate(360deg);
    }

    37.5% {
        border-color: rgb(1, 103, 170) transparent transparent transparent;
    }

    50% {
        border-color: rgb(195, 8, 112) transparent transparent transparent;
        transform: rotate(720deg);
    }

    62.5% {
        border-color: rgb(195, 8, 112) transparent transparent transparent;
    }

    75% {
        border-color: rgb(255, 210, 0) transparent transparent transparent;
        transform: rotate(1080deg);
    }

    87.5% {
        border-color: rgb(255, 210, 0) transparent transparent transparent;
    }

    100% {
        border-color: rgb(40, 168, 223) transparent transparent transparent;
        transform: rotate(1440deg);
    }
}

.loading {
    animation: loading 4s linear infinite;
    border-radius: 50%;
    border-style: solid;
    border-width: 4px;
    height: 44px;
    width: 44px;
}
