.field--password {
    position: relative;

    .eye-icon {
        position: absolute;

        width: 24px;
        height: 24px;
        margin: 12px 16px;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
    }
}
